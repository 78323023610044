.card {
    border: none;
    // min-height: 380px;
    overflow: hidden;
    border-radius: 0 !important;
    margin-bottom: $grid-gutter-width*2;
    @include media-breakpoint-down (md){
    margin-bottom: $grid-gutter-width;
    }
    @include media-breakpoint-down (sm){
    margin-bottom: $grid-gutter-width/2;
    }
    @extend .layer-1;
    // @extend .shadow-lg;

    .card-img-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-body {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        p.moreInfo {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.card-product-overview {
        @extend .card-body;
        @extend .card-icon-2;
        @extend .justify-content-end;
        // @extend .hover-bg-primary-3;
        background: #EDF2F5;
        border-radius: 0px;
        border: none;
        transition-duration: .5s;
        &:hover {
            box-shadow: 0 20px 40px rgba($primary-3, .3);
            background-color: $white;
        }

        .moreLink {
            @extend .mb-0;

        }

        .bg-image {
            background-repeat: no-repeat;
            background-position: center center;
            @include media-breakpoint-down (sm) {
                display: none !important;
            }
        }

        .icn {
            background: none;
        }

        .d-between {
            @extend .layer-2;
            @extend .d-flex;
            @extend .justify-content-between;
        }
    }
}

.blogListItem {
    @extend .card;
    // @extend .bg-dark;
    @extend .text-light;
    @extend .hover-shadow-3d;
    background-color: theme-color(primary-2) !important;
    transition: all $transition-time ease-in-out;

    .itemInfo {
        color: theme-color(primary-3);
        @extend .mb-2;
    }

    p.title {
        color: $white !important;
        @extend .mb-0;
        @extend .lead;
    }

    p.moreInfo {
        color: theme-color(primary) !important;
        @extend .lead;
    }

}