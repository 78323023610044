body {
    background-color: #fff;
}

.wrapper {
    // padding-top: 80px;
    background-color: white;
    margin: 0 auto;

    max-width: 1440px;
    width: 100%;
    // padding-bottom: 3em;
}

main {
    @extend .py-4;
}

.iso-item {
    @include media-breakpoint-only(xl) {
        height: 850px;
    }
    @include media-breakpoint-only(lg) {
        height: 700px !important;
    }
    @include media-breakpoint-only(md) {
    }
    @include media-breakpoint-only(sm) {
        height: 620px !important;
    }

}

.iso-item-imageheight {
    img {
        object-fit: contain;
    }
    @include media-breakpoint-only(xl) {
        height: 450px !important;
    }
    @include media-breakpoint-only(lg) {
        height: 350px !important;
    }
    @include media-breakpoint-only(md) {
        height: 275px !important;
    }
    @include media-breakpoint-only(sm) {
        height: 175px !important;
    }
}

.full-width {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    position: relative;
}

.bg-white {
    background-color: white;
}


.color-primary {
    color: theme-color('primary');
}

.banner-strap {
    // background-color: $color-primary;
    // @extend .text-white;
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: theme-color('primary-3');
}



.noBg {
    .navbar {
        &.bg-white {
            // background: #EFEFEF !important;
        }
    }

    .breadcrumb {
        background: #EFEFEF;
    }

    main {
        @extend .pt-7;
    }
    section {
        &:first-child {
            padding-top: $grid-gutter-width*4;
            @include media-breakpoint-down (md) {
                padding-top: $grid-gutter-width*3;
            }
            @include media-breakpoint-down (sm) {
                padding-top: $grid-gutter-width*2;
            }
        }
    }

}

figure {
    margin: 0 0 1rem;
}
