.colored-bars {
    position: fixed;
    top: 0;
    z-index: 9;
    @include make-row();
    width: 103% !important;
    @extend .layer-2;
    .bar {
        @include make-col-ready();
        height: 14px;
        transform: skewX(-20deg);
        &-one {
            background: theme-color('primary-2');
            @include media-breakpoint-up(xs) {
                @include make-col(4);
            }
        }
        &-two {
            background: theme-color('primary');
            @include media-breakpoint-up(xs) {
                @include make-col(3);
            }
            
        }
        &-three {
            background: #EDF2F5;
            @include media-breakpoint-up(xs) {
                @include make-col(2);
            }
            
        }
        &-four {
            background: theme-color('primary-3');
            @include media-breakpoint-up(xs) {
                @include make-col(3);
            }

        }
    }
}
