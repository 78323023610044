// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

$font-size-base:              1.3rem; // Assumes the browser default, typically `16px`


// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:      #E2B800;
$primary-2:     #303030;
$primary-3:     #889DAD;
$primary-4:     #B4C7D4;
$primary-5:     #EDF2F5;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "primary-4":  $primary-4,
    "primary-5":  $primary-5,
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "primary-4":  $primary-4,
    "primary-5":  $primary-5,

);

$link-color:  theme-color(primary);

$border-radius: 0px;
$border-width: 2px;
$border-color: $primary;

$font-family-sans-serif:      'Barlow Condensed', sans-serif;
$font-family-base:            'Barlow Condensed', serif;
$headings-font-family:        'Barlow Condensed', sans-serif;

$font-weight-light:           400;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-bold:            600;

$headings-font-weight:        600;
$display1-weight:             600;
$display2-weight:             600;
$display3-weight:             600;
$display4-weight:             600;


// $breadcrumb-padding-y:        0;
$breadcrumb-padding-x:        0;
$breadcrumb-margin-bottom:    0;
$breadcrumb-bg:               $white;
$breadcrumb-active-color:     $primary !important;
// $breadcrumb-item-padding:     
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 960px,
  lg: 1366px,
  xl: 1440px
);

