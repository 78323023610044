.breadcrumb {
    .breadcrumb-item {
        @extend .h4;
        .link-node {
            @extend .h4;
            @extend .mb-0;
        }
    }
}

.navbar-container {
    @extend .layer-2;
    height: 120px;
    // background-color: $white !important;
    .navbar-dark.scrolled {
    }

    font-family: $headings-font-family;
    font-size: 1.25em !important;
    letter-spacing: .5px;

    .navbar-toggler {
        .icon {
            height: 3rem;
        }
    }
    .navbar-brand {
        @include media-breakpoint-down(md) {
            max-width: 150px;
        }
        @include media-breakpoint-down(sm) {
            max-width: 120px;
        }
    }
    .nav-link {
        @extend .position-relative;

        .count {
            @extend .position-absolute;
            right: 0px;
            top: 0px;

            width: 16px !important;
            height: 16px !important;
            line-height: 16px !important;
            font-size: 0.75rem;
            background: theme-color('primary');
            text-align: center;
            border-radius: 50%;
            color: white;

            @include media-breakpoint-down (md) {
                // width: 20px !important;
                // height: 20px !important;
                top: .5rem;
                // font-size: 1rem;
                padding-top: .04rem;
                margin-left: .2rem;
                right: inherit;
                // left: 0;
            }


            // @extend .badge;            
            // @extend .badge-primary;
            // border-radius: 10px;
            // padding: 0.3rem 0.3rem;
        }
    }

    .first-level {
        &.nav-link {
            color: theme-color('primary-2');

            // &:hover {
            //     color: #f09;
            // }
        }
    }

    .nav-item {
        a {
            &.nav-link {
                font-weight: $font-weight-bold;
                white-space: nowrap;
                color: theme-color('primary-3');

                &.is-active,
                &.is-currend {
                    color: theme-color('primary-2') !important;
                }

                // &:hover {
                //     color: theme-color('primary');
                // }
            }
        }

        &:hover,
        &.show {
            a {
                &.nav-link {
                    color: theme-color('primary-2') !important;
                }
            }
        }

        .icon {
            display: none;
        }

        .dropdown-grid-menu {
            @extend .py-1;
            border-radius: 0;
            border: none;
            background: white;
        }
    }

    .dropdown-grid-menu {
        @extend .p-0;
        min-width: 250px;
    }

    .dropdown-item {
        @extend .px-2;
        @extend .py-1;
        font-weight: $font-weight-normal;
        font-size: $h4-font-size;
        border-bottom: 1px dotted lighten(theme-color('primary-3'),25%) !important;
        
        &.is-last {
            border-bottom: none !important;
        }

        .icon {
            display: initial;
        }

        color: theme-color('primary-3');

        span {
            border-bottom: none;
        }

        &:hover {
            background-color: theme-color('primary');
            color: white;
            span {
                color: white;
            }
            &.is-current {
                color: white !important;
            }
        }
    }

    .is-active,
    .is-current {
        color: theme-color('primary-2') !important;

        .bordered {
            @extend .pb-1;
            border-bottom: 2px solid theme-color('primary-2');

            @include media-breakpoint-down (md) {
                border: none;
            }
        }

        // color: theme-color('primary') !important;

        &.dropdown-item {
            color: theme-color('primary') !important;
        }

    }

    .first-level {
        svg {
            transform: rotate(-90deg);
        }
    }

    .btn {
        position: relative;
        font-weight: $font-weight-normal;
        top: .75em;
        color: theme-color('primary-2');
        border-color: theme-color('primary')
    }



    .navToggle {
        background-color: none;
    
        svg {
            transform: rotate(-90deg);
        }
    }
    
    
    button {
        &.navbar-toggler {
            height: 100%;
            width: 5rem;
            border: 2px solid theme-color('primary') !important;
            border-radius: .325em;
            @extend .mt-2;
    
            @include media-breakpoint-down (sm) {
                margin-top: 0 !important;
            }
        }
    
        .navbar-toggler-open,
        .navbar-toggler-close {}
    
        .navbar-toggler-close {
            left: calc(50% - 1.5rem);
        }
    }    
}

.nav-link {
    &.active {
        color: theme-color(primary);
    }
}
.navbar-brand img{
    transition: all .3s ease-in-out;

    .scrolled & {
        max-width: 75%;
    }

}
// .noBg {
//     .navbar-container {

//         .is-active,
//         .is-current {
//             color: theme-color('primary') !important;
//         }

//         .navbar-brand {
//             color: theme-color('primary') !important;
//         }

//         .btn {
//             position: relative;
//             top: .75em;
//             color: theme-color('primary-2') !important;

//             &:hover {
//                 color: $white !important;
//             }
//         }
//     }


//     .nav-item {


//         &:hover,
//         &.show {
//             a {
//                 &.nav-link {
//                     color: theme-color('primary');
//                 }
//             }
//         }
//     }

// }

// .navbar-collapse {
//     @extend .mt-3;
//     @extend .mt-lg-0;

//     &:last-child {
//         @extend .mt-0;
//     }

//     .nav-item a {
//         border-top: 2px solid $primary-2;

//         @include media-breakpoint-up (lg) {
//             border: none;
//         }
//     }
// }




// .navbar-brand {
//     font-weight: $font-weight-bold;

//     img {
//         // margin-right: 0.5em;
//         // margin-top: 0.5em;
//         // margin-bottom: 0.5em;
//         transition: .3s;
//         height: 70px;
//         max-width: 298px;

//         @include media-breakpoint-down(md) {
//             height: 55px;
//             // margin-top: .5em;
//         }

//         @include media-breakpoint-down(sm) {
//             height: 40px;
//         }

//         .scrolled & {
//             height: 50px;
//             margin: 0 .75em 0 0;
//             transition: .3s;

//             @include media-breakpoint-down(sm) {
//                 height: 40px;
//             }
//         }
//     }

// }


// .navbar-light {
//     .navbar-toggler {
//         svg {
//             padding: .5rem;

//             path {
//                 fill: theme-color('primary-2');
//             }
//         }
//     }
// }
