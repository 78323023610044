.form-amount {
    width: 64px !important;
    height: 54px;
    border-radius: 0;
    border-top: none;
    border-bottom: none;
    border-left: none;
    text-align: center;
    background-color: theme-color(primary-2);
    &:focus {
        background-color: theme-color(primary-3);
        color: $black;
    }
    color: $white;
}


form {
    
    fieldset {
        @extend .form-row;

        .clearfix {
            @extend .col-12;
        }

        input,
        textarea {
            @extend .form-control;
        }

        label {
            margin: 1em 0 0;
            font-size: 0.85em;
        }
    }

    .error {

        input,
        textarea {
            border-color: $red;
        }

        .help-inline {
            color: $red;
            font-size: 0.85em;
        }
    }

    .form-navigation {
        ul {
            list-style-type: none;
            padding: 1.5em 0.5em 0;
        }
    }
}

.mauticform-innerform {
    margin-right: -25px;
//     @extend .container;
}


.mauticform-page-wrapper {
    @extend .row;
    margin: 0 !important;
    width: 100%;

    color: theme-color('primary-2');
    [class*="mauticform-field-"] {
        padding-left: 0 !important;
        // padding-right: 0 !important;
        &:last-child{
            // padding-right: 0 !important;
        }
    }

    .mauticform-label {
        @extend .h3;
        width: 100%;
    }

    h3 {
        @extend .mt-4;
    }

    .small {
        .mauticform-label {
            @extend .mt-2;
            font-weight: $font-weight-normal;
        }
    }

}

#mauticform_label_offertauftragsanfrage_datei_auswahlen {
    display: none;
}

form {}

.mauticform-row {
    float: left;
    // display: flex !important;
    // @extend .row;
    // @extend .container;
    // @include make-col(12);
}

.mauticform-text,
.mauticform-email,
.mauticform-tel,
.mauticform-select,
.mauticform-button-wrapper {
    
    @extend .form-group;
    
    input,
    textarea {
        @extend .form-control;
        border: none;
        background-color: #efefef;
        font-size: 1em*0.865;

        &::placeholder {
            color: rgba(theme-color('primary-2'), 0.5);
        }
    }
}
.selectize-input {
    background-color: theme-color(primary-5) !important;
    box-shadow: none !important;
    border: none !important;
}
.mauticform-text,
.mauticform-email,
.mauticform-tel,
.mauticform-select {
    input, select, textarea {
        background-color: theme-color(primary-5) !important;
        color: theme-color(primary-4);
        border: none !important;
    }
}
.mauticform-button-wrapper {
    // @extend .justify-content-end;
    
    // .btn-default {
        //     @extend .btn-primary-2;
        //     font-weight: $font-weight-normal;
        // }
        
    }
    
    .mauticform-select {
        select {
            background-color: #efefef;
            // @extend .custom-select;
            @extend .form-control;
        }
    }
    
    .mauticform-file {}
    
    .mauticform-errormsg {
        color: $red;
        font-size: 0.75em;
    }
    
    .mauticform-has-error {
        .mauticform-input {
            border: 2px dashed theme-color('primary-1') !important;
        }
    }
    
    .mauticform-checkboxgrp {
        @extend .row;
        @extend .container;
        
        .mauticform-checkboxgrp-row {
            @extend .col-md-6;
            padding-left: 0;
            label {
                @extend .mb-0;
            }
        }
    }
    
    
    .mauticform-post-success {
        transition: all 0.5s;
        
        .mauticform-message {
            @extend h4;
            color: theme-color('primary');
            opacity: 1;
        }
        
        .mauticform-innerform {
            opacity: 0;
        }
        
        // border: 4px solid orange;
    }
    ::placeholder {
        color: theme-color(primary-3) !important;
    }
    
    